<template>
  <div id="app">
    <!-- 固定灯控件区域 -->
    <div class="lights">
      <div 
        v-for="light in lights" 
        :key="light.number" 
        :class="['light', { 'blinking': light.blinking, 'show-number': light.showNumber, 'permanent-red': light.permanentRed }]"
        @click="filterByLight(light.number)"
      >
        <div class="light-number">{{ light.number }}号</div> <!-- 显示灯光编号 -->
        <div class="progress">{{ light.progress }}/{{ light.total }}</div> <!-- 显示进度 -->
      </div>
    </div>

    <div class="controls">
      <input type="file" @change="onFileChange" />
      <input v-model="sortCode" placeholder="输入成品条码进行分拣" @input="sortPanel" />
      <button @click="submitSortedPanels">提交分拣数据</button>
      <label>
        <input type="checkbox" v-model="showVirtualLights"> 显示虚拟灯控列
      </label>
      <label>
        <input type="checkbox" v-model="sortByCabinet" :disabled="currentOrderPanels.some(panel => panel.sorted)" @change="filterDataBySortOption"> 按柜分拣
      </label>
      <label>
        <input type="checkbox" v-model="sortByOrder" :disabled="currentOrderPanels.some(panel => panel.sorted)"> 按订单分拣
      </label>
      <label>
        <input type="checkbox" v-model="sortByPanel" :disabled="currentOrderPanels.some(panel => panel.sorted)"> 按板件分拣
      </label>
      <label>
        启动电子标签:
        <input v-model="electronicTagStatus" placeholder="输入启用或停用" />
      </label>
    </div>

    <h2>
  待分拣订单：{{ totalPendingCabinets }}个柜体；{{ totalPendingPanels }}块板件
  ；正在分拣订单：
  <span v-if="currentOrderNo">
    {{ currentOrderNo }}订单 - 余{{ remainingCabinets }}个柜体 - 余{{ remainingPanels }}块板件
  </span>
  <span v-else>无</span>
</h2>
    <table>
      <tbody>
        <template v-for="(order, orderIndex) in filteredPendingOrders" :key="order.orderNo">
          <tr @click="toggleOrder(orderIndex, 'pending')" class="order-header">
            <td colspan="10">
              {{ order.orderNo }} (点击展开/折叠)； {{ order.totalCabinets }}个柜体； 共计{{ order.totalPanels }}块板件；
            </td>
          </tr>
          <template v-if="order.expanded || showFiltered">
            <template v-for="(cabinet, cabinetIndex) in order.cabinets" :key="cabinet.cabinetNo">
              <tr @click="toggleCabinet(orderIndex, cabinetIndex, 'pending')" class="cabinet-header">
                <td colspan="10" class="cabinet-name">
                  <span class="cabinet-text">{{ cabinet.cabinetName }} (点击展开/折叠)； {{ cabinet.panelCount }}块板件</span>
                </td>
              </tr>
              <tr v-if="cabinet.expanded || showFiltered">
                <td colspan="10">
                  <table class="sub-table">
                    <thead>
                      <tr>
                        <th v-if="showVirtualLights">虚拟灯控</th>
                        <th>订单编号</th>
                        <th>柜体名称</th>
                        <th>板件名称</th>
                        <th>柜体编号</th>
                        <th>板件编号</th>
                        <th>订单名称</th>
                        <th>成品条码</th>
                        <th>分拣状态</th>
                        <th>是否有孔</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(panel, panelIndex) in cabinet.panels" 
                          :key="panelIndex" 
                          :ref="panelRefName(panel._Code)" 
                          :ref-in-for="true" 
                          :class="{ sorted: panel.sorted, highlight: panel.highlight }">
                        <td v-if="showVirtualLights">
                          <div :class="['virtual-light', { 'blinking': panel.blinking, 'red': panel.blinkingFinished }]" 
                               :data-number="panel.blinkingFinished ? panel._cabinet_number : ''">
                          </div>
                        </td>
                        <td>{{ panel._OrderNo }}</td>
                        <td>{{ panel._CstName }}</td>
                        <td>{{ panel._Name }}</td>
                        <td>{{ panel._cabinet_number }}</td>
                        <td>{{ panel._panel_number }}</td>
                        <td>{{ panel._project_name }}</td>
                        <td>{{ panel._Code }}</td>
                        <td :style="{ color: panel.sorted ? 'blue' : 'red' }">
                          {{ panel.sorted ? '已分拣' : '未分拣' }}
                        </td>
                        <td>{{ panel._has_holes === '有孔' ? '有孔' : '' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </template>
          </template>
        </template>
      </tbody>
    </table>

    <h2>已分拣订单</h2>
    <table>
      <tbody>
        <template v-for="(order, orderIndex) in filteredSortedOrders" :key="order.orderNo">
          <tr @click="toggleOrder(orderIndex, 'sorted')" class="order-header">
            <td colspan="10">
              {{ order.orderNo }} (点击展开/折叠)； {{ order.totalCabinets }}个柜体； 共计{{ order.totalPanels }}块板件；
            </td>
          </tr>
          <template v-if="order.expanded || showFiltered">
            <template v-for="(cabinet, cabinetIndex) in order.cabinets" :key="cabinet.cabinetNo">
              <tr @click="toggleCabinet(orderIndex, cabinetIndex, 'sorted')" class="cabinet-header">
                <td colspan="10" class="cabinet-name">
                  <span class="cabinet-text">{{ cabinet.cabinetName }} (点击展开/折叠)； {{ cabinet.panelCount }}块板件</span>
                </td>
              </tr>
              <tr v-if="cabinet.expanded || showFiltered">
                <td colspan="10">
                  <table class="sub-table">
                    <thead>
                      <tr>
                        <th v-if="showVirtualLights">虚拟灯控</th>
                        <th>订单编号</th>
                        <th>柜体名称</th>
                        <th>板件名称</th>
                        <th>柜体编号</th>
                        <th>板件编号</th>
                        <th>订单名称</th>
                        <th>成品条码</th>
                        <th>分拣状态</th>
                        <th>是否有孔</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(panel, panelIndex) in cabinet.panels" 
                          :key="panelIndex" 
                          :class="{ sorted: panel.sorted, highlight: panel.highlight }">
                        <td v-if="showVirtualLights">
                          <div :class="['virtual-light', { 'blinking': panel.blinking, 'red': panel.blinkingFinished }]" 
                               :data-number="panel.blinkingFinished ? panel._cabinet_number : ''">
                          </div>
                        </td>
                        <td>{{ panel._OrderNo }}</td>
                        <td>{{ panel._CstName }}</td>
                        <td>{{ panel._Name }}</td>
                        <td>{{ panel._cabinet_number }}</td>
                        <td>{{ panel._panel_number }}</td>
                        <td>{{ panel._project_name }}</td>
                        <td>{{ panel._Code }}</td>
                        <td :style="{ color: panel.sorted ? 'blue' : 'red' }">
                          {{ panel.sorted ? '已分拣' : '未分拣' }}
                        </td>
                        <td>{{ panel._has_holes === '有孔' ? '有孔' : '' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </template>
          </template>
        </template>
      </tbody>
    </table>

    <!-- 覆盖层 -->
    <div v-if="overlayVisible" class="overlay">
      <div class="overlay-content">
        <div class="cabinet-panel" v-if="overlayText">
          <template v-if="isGeneralMessage">
            <span :class="['general-message', { 'large': overlayText.includes('该条码不存在') || overlayText.includes('非此次分拣订单') }]">
              {{ overlayText }}
            </span>
          </template>
          <template v-else>
            <span class="highlight-red">{{ overlayText.split('号柜')[0] }}</span>
            <span class="highlight-blue">号柜</span>
            <template v-if="!sortByCabinet">
              <span class="highlight-red">{{ overlayText.split('号柜')[1]?.split('号板')[0] }}</span>
              <span class="highlight-blue">号板</span>
            </template>
            <span class="highlight-blue">
              {{ overlayText.includes('有孔') ? ', 有孔' : '' }}
            </span>
          </template>
        </div>
      </div>
    </div>

    <!-- 完成提示对话框 -->
    <div v-if="showCompletionMessage" class="completion-dialog">
      <div class="completion-content">
        <p>{{ overlayText }}</p>
        <button @click="confirmCompletion">确认</button>
        <!-- <button @click="cancelCompletion">关闭</button> -->
      </div>
    </div>
  </div>
</template>



<script>
import { reactive } from 'vue';

export default {
  data() {
    return {
      orders: [],
      sortCode: "",
      overlayVisible: false,
      overlayText: "",
      isGeneralMessage: false,
      sortedPanels: [],
      currentOrderNo: "",
      currentOrderPanels: [],
      pendingOrders: [],
      sortedOrders: [],
      lights: reactive([]),
      showVirtualLights: false,
      filterLightNumber: null,
      showFiltered: false,
      showCompletionMessage: false,
      sortByCabinet: false,
      sortByOrder: false,
      sortByPanel: false, 
      filteredOutPanels: new Map(), // 用于临时保存被过滤掉的板件数据
      electronicTagStatus: '',
      totalPendingCabinets: 0,  // 总柜体数
    totalPendingPanels: 0,    // 总板件数
    remainingCabinets: 0,     // 当前订单剩余柜体数
    remainingPanels: 0        // 当前订单剩余板件数
    };
  },
  methods: {
    filterDataBySortOption() {
      if (this.sortByCabinet) {
        // 清空先前的临时数据存储
        this.filteredOutPanels.clear();

        this.pendingOrders.forEach(order => {
          order.cabinets.forEach(cabinet => {
            const panelsToFilterOut = cabinet.panels.filter(panel => panel._Code.endsWith('0'));
            if (panelsToFilterOut.length > 0) {
              // 保存被过滤掉的板件数据
              this.filteredOutPanels.set(cabinet.cabinetNo, panelsToFilterOut);
              // 过滤掉尾号为 '0' 的板件
              cabinet.panels = cabinet.panels.filter(panel => !panel._Code.endsWith('0'));
            }
          });
        });
      } else {
        // 恢复原始数据
        if (this.filteredOutPanels.size > 0) {
          this.pendingOrders.forEach(order => {
            order.cabinets.forEach(cabinet => {
              if (this.filteredOutPanels.has(cabinet.cabinetNo)) {
                // 恢复被过滤掉的板件数据
                const restoredPanels = this.filteredOutPanels.get(cabinet.cabinetNo);
                cabinet.panels = cabinet.panels.concat(restoredPanels);
              }
            });
          });
          this.filteredOutPanels.clear(); // 清空临时数据存储
        }
      }

      // 重新计算数量
      this.recalculateCounts();

      // 强制视图更新
      this.$forceUpdate();
    },

    recalculateCounts() {
  let totalCabinets = 0;
  let totalPanels = 0;

  this.pendingOrders.forEach(order => {
    // 计算未分拣的柜体数量
    order.totalCabinets = order.cabinets.filter(cabinet =>
      cabinet.panels.some(panel => !panel.sorted)
    ).length;
    // 计算未分拣的板件数量
    order.totalPanels = order.cabinets.reduce((total, cabinet) =>
      total + cabinet.panels.filter(panel => !panel.sorted).length
    , 0);
    
    totalCabinets += order.totalCabinets;
    totalPanels += order.totalPanels;
  });

  this.totalPendingCabinets = totalCabinets;
  this.totalPendingPanels = totalPanels;

  if (this.currentOrderNo) {
    const currentOrder = this.pendingOrders.find(order => order.orderNo === this.currentOrderNo);
    if (currentOrder) {
      this.remainingCabinets = currentOrder.cabinets.filter(cabinet => 
        cabinet.panels.some(panel => !panel.sorted)
      ).length;
      this.remainingPanels = currentOrder.cabinets.reduce((total, cabinet) => 
        total + cabinet.panels.filter(panel => !panel.sorted).length, 0
      );
    }
  } else {
    this.remainingCabinets = 0;
    this.remainingPanels = 0;
  }
},

confirmCompletion() {
  this.showCompletionMessage = false;

  const completedOrderIndex = this.pendingOrders.findIndex(order => order.orderNo === this.currentOrderNo);
  if (completedOrderIndex > -1) {
    this.sortedOrders.push(this.pendingOrders.splice(completedOrderIndex, 1)[0]);
  }

  this.lights = reactive([]); // 清空灯光
  console.log("All lights reset");

  this.currentOrderNo = "";
  this.currentOrderPanels = [];

  // 重置剩余柜体和板件数
  this.remainingCabinets = 0;
  this.remainingPanels = 0;

  // 重新计算数量
  this.recalculateCounts();
},

    cancelCompletion() {
      this.showCompletionMessage = false;
    },

    onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const content = e.target.result;
            this.parseJSON(content);
            this.filterDataBySortOption(); // 新增
          } catch (error) {
            console.error("Error parsing JSON:", error);
            alert("Error parsing JSON: " + error.message);
          }
        };
        reader.readAsText(file);
      }
    },

    parseJSON(content) {
  try {
    const data = JSON.parse(content);
    console.log("Parsed data:", data);

    if (!data.panels || !Array.isArray(data.panels)) {
      throw new Error("Parsed data does not contain a valid panels array");
    }

    const ordersMap = new Map();
    data.panels.forEach((item) => {
      if (!ordersMap.has(item._OrderNo)) {
        ordersMap.set(item._OrderNo, {
          orderNo: item._OrderNo,
          expanded: false,
          cabinets: new Map(),
          totalCabinets: 0, // 初始化柜体数量
          totalPanels: 0    // 初始化板件数量
        });
      }

      const order = ordersMap.get(item._OrderNo);
      if (!order.cabinets.has(item._cabinet_number)) {
        order.cabinets.set(item._cabinet_number, {
          cabinetNo: item._cabinet_number,
          cabinetName: item._CstName,
          expanded: false,
          panels: [],
          panelCount: 0 // 初始化板件数量
        });
        order.totalCabinets += 1; // 增加柜体数量
      }

      order.cabinets.get(item._cabinet_number).panels.push({
        ...item,
        sorted: false,
        highlight: false,
        blinking: false,
        blinkingFinished: false,
      });

      order.cabinets.get(item._cabinet_number).panelCount += 1; // 增加柜体的板件数量
      order.totalPanels += 1; // 增加订单中的板件数量
    });

    this.orders = Array.from(ordersMap.values()).map(order => {
      order.cabinets = Array.from(order.cabinets.values());
      return order;
    });
    this.pendingOrders = this.orders;
    this.sortedOrders = [];

    // 初次加载时计算数量
    this.recalculateCounts();
  } catch (error) {
    console.error("Error processing JSON data:", error);
    alert("Error processing JSON data: " + error.message);
  }
},

    toggleOrder(orderIndex, type) {
      const targetOrders = type === 'pending' ? this.pendingOrders : this.sortedOrders;
      targetOrders[orderIndex].expanded = !targetOrders[orderIndex].expanded;
    },

    toggleCabinet(orderIndex, cabinetIndex, type) {
      const targetOrders = type === 'pending' ? this.pendingOrders : this.sortedOrders;
      targetOrders[orderIndex].cabinets[cabinetIndex].expanded = !targetOrders[orderIndex].cabinets[cabinetIndex].expanded;
    },

    sortPanel() {
  const trimmedInputCode = this.sortCode.trim();

  // 特定条码用于勾选或取消勾选分拣方式
  if (trimmedInputCode === "18056105335") {
    this.sortByCabinet = true;
    this.filterDataBySortOption(); // 执行数据处理方法
    this.sortCode = ''; // 清空输入框内容
    return;
  } else if (trimmedInputCode === "180561053350000") {
    this.sortByCabinet = false;
    this.filterDataBySortOption(); // 执行数据处理方法
    this.sortCode = ''; // 清空输入框内容
    return;
  }

  if (!this.sortCode.trim()) {
    return;
  }

  // 检查是否有勾选任何一个分拣方式
  if (!this.sortByCabinet && !this.sortByOrder && !this.sortByPanel) {
    this.isGeneralMessage = true;
    this.overlayText = "还未选择分拣方式请选择分拣方式";
    this.overlayVisible = true;
    setTimeout(() => {
      this.overlayVisible = false;
    }, 3000);
    this.speakText("还未选择分拣方式请选择分拣方式");
    this.sortCode = '';
    return;
  }

  let trimmedSortCode = this.sortCode.trim();
  if (this.sortByCabinet && trimmedSortCode.endsWith('0')) {
    trimmedSortCode = trimmedSortCode.slice(0, -1);
  }

  let codeExistsInCurrentOrder = false;
  let codeExistsInAnyOrder = false;
  let codeSorted = false;
  let orderNoForOtherPanel = "";

  this.orders.forEach(order => {
    order.cabinets.forEach(cabinet => {
      cabinet.panels.forEach(panel => {
        if (panel._Code === trimmedSortCode) {
          codeExistsInAnyOrder = true;
          if (order.orderNo !== this.currentOrderNo) {
            orderNoForOtherPanel = order.orderNo;
          }
        }
      });
    });
  });

  if (!this.currentOrderNo) {
    this.orders.some(order => {
      return order.cabinets.some(cabinet => {
        return cabinet.panels.some(panel => {
          if (panel._Code === trimmedSortCode) {
            this.currentOrderNo = order.orderNo;
            this.currentOrderPanels = order.cabinets.flatMap(cab => cab.panels);

            let maxCabinetNumber = 0;
            order.cabinets.forEach(cabinet => {
              const cabinetNumber = Number(cabinet.cabinetNo);
              if (cabinetNumber > maxCabinetNumber) {
                maxCabinetNumber = cabinetNumber;
              }
            });

            this.lights = reactive(Array.from({ length: maxCabinetNumber }, (_, index) => ({
              number: index + 1,
              blinking: false,
              showNumber: true,
              progress: 0,
              total: 0,
            })));

            order.cabinets.forEach(cabinet => {
              const cabinetNumber = Number(cabinet.cabinetNo);
              if (cabinetNumber <= this.lights.length) {
                this.lights[cabinetNumber - 1].total = cabinet.panels.length;
                this.lights[cabinetNumber - 1].progress = 0;
                console.log(`Initializing light ${cabinetNumber} with total: ${cabinet.panels.length}`);
              }
            });

            return true;
          }
          return false;
        });
      });
    });
  }

  if (!codeExistsInAnyOrder) {
    this.isGeneralMessage = true;
    this.overlayText = "该条码不存在";
    this.overlayVisible = true;
    setTimeout(() => {
      this.overlayVisible = false;
    }, 5000);
    this.speakText("该条码不存在");
    this.sortCode = '';
    return;
  }

  this.currentOrderPanels.forEach(panel => {
    if (panel._Code === trimmedSortCode) {
      codeExistsInCurrentOrder = true;
      if (panel.sorted) {
        codeSorted = true;
      } else {
        // 判断电子标签状态并打印柜号
        if (this.electronicTagStatus === '启动') {
          console.log(`我是电子标签${panel._cabinet_number}号标签`);
        }

        panel.sorted = true;

        this.sortedPanels.push({
          orderNo: this.currentOrderNo,
          panelName: panel._Name,
          code: panel._Code,
        });

        const cabinetNumber = Number(panel._cabinet_number);
        const light = this.lights[cabinetNumber - 1];

        light.progress += 1;

        if (light.progress >= light.total && light.total > 0) {
          light.blinking = false;
          light.showNumber = true;
          light.permanentRed = true;
        }

        this.orders.forEach(order => {
          if (order.orderNo === this.currentOrderNo) {
            order.expanded = true;
            order.cabinets.forEach(cabinet => {
              if (cabinet.cabinetNo === panel._cabinet_number) {
                cabinet.expanded = true;
              }
            });
          }
        });

        this.$nextTick(() => {
          const panelElement = this.$refs[this.panelRefName(panel._Code)][0];
          if (panelElement) {
            setTimeout(() => {
              panelElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
              panelElement.classList.add('highlight-large');
              panel.highlight = true;

              if (cabinetNumber <= this.lights.length && !light.isFull) {
                light.blinking = true;
                light.showNumber = true;
                panel.blinking = true;
                panel.blinkingFinished = true;
                setTimeout(() => {
                  light.blinking = false;
                  panel.blinking = false;
                }, 5000);
              }

              setTimeout(() => {
                panel.highlight = false;
                panelElement.classList.remove('highlight-large');
              }, 5000);
            }, 100);
          }
        });

        this.isGeneralMessage = false;
        let overlayText;
        if (this.sortByCabinet) {
          overlayText = `${panel._cabinet_number}号柜${panel._has_holes === '有孔' ? ', 有孔' : ''}`;
        } else {
          overlayText = `${panel._cabinet_number}号柜-${panel._panel_number}号板${panel._has_holes === '有孔' ? ', 有孔' : ''}`;
        }
        
        this.overlayText = overlayText;
        this.overlayVisible = true;
        setTimeout(() => {
          this.overlayVisible = false;
        }, 5000);
        this.speakText(overlayText);
      }
    }
  });

  // 更新剩余柜体和板件数
  this.recalculateCounts();

  if (!codeExistsInCurrentOrder) {
    this.isGeneralMessage = true;
    const message = `该板件为${orderNoForOtherPanel}订单板件，非此次分拣订单`;
    this.overlayText = message;
    this.overlayVisible = true;
    setTimeout(() => {
      this.overlayVisible = false;
    }, 5000);
    this.speakText(message);
  } else if (codeSorted) {
    this.isGeneralMessage = true;
    this.overlayText = "该条码已分拣";
    this.overlayVisible = true;
    setTimeout(() => {
      this.overlayVisible = false;
    }, 5000);
    this.speakText("该条码已分拣");
  }

  const allSorted = this.currentOrderPanels.every(panel => panel.sorted);
  if (allSorted) {
    this.isGeneralMessage = true;
    this.overlayText = "当前订单所有板件已分拣完成";
    this.overlayVisible = true;
    this.showCompletionMessage = true;
    this.speakText("当前订单所有板件已分拣完成");

    // 将已完成订单移动到已分拣订单
    const completedOrderIndex = this.pendingOrders.findIndex(order => order.orderNo === this.currentOrderNo);
    if (completedOrderIndex > -1) {
      this.sortedOrders.push(this.pendingOrders.splice(completedOrderIndex, 1)[0]);
    }

    // 重置当前订单状态
    this.currentOrderNo = "";
    this.currentOrderPanels = [];

    // 更新待分拣和正在分拣订单的数据
    this.recalculateCounts();
  }

  this.sortCode = '';
},


    panelRefName(code) {
      return `panel-${code}`;
    },

    speakText(text) {
    // 创建一个新的 SpeechSynthesisUtterance 实例
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = "zh-CN"; // 设置语言
    utterance.rate = 1.1;     // 调整语速
    utterance.pitch = 1;      // 调整音调

    // 获取可用的语音列表
    let voices = window.speechSynthesis.getVoices();

    // 检查语音列表是否为空，即是否尚未加载
    if (voices.length === 0) {
      // 监听 onvoiceschanged 事件，一旦声音列表加载完成，重新调用 speakText 方法
      window.speechSynthesis.onvoiceschanged = () => {
        voices = window.speechSynthesis.getVoices();
        this.speakText(text);
      };
    } else {
      // 查找特定的声音对象
      console.log(voices);
      const selectedVoice = voices.find(voice => voice.name.includes("Microsoft HsiaoChen Online (Natural) - Chinese (Taiwan)"));

      // 设置语音对象
      if (selectedVoice) {
        utterance.voice = selectedVoice;
      } else {
        console.error("未找到指定的声音对象。");
      }

      // 进行语音合成
      window.speechSynthesis.speak(utterance);
    }
  },

    submitSortedPanels() {
      const dataToSubmit = this.sortedPanels.map(panel => ({
        orderNo: `订单${panel.orderNo}`,
        panelName: panel.panelName,
        code: panel.code
      }));

      const dataString = dataToSubmit.map(item => `订单编号: ${item.orderNo}, 板件名称: ${item.panelName}, 成品条码: ${item.code}`).join('\n');
      alert(`将提交以下数据:\n${dataString}`);

      // 示例代码
      fetch('/api/sorted-panels', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSubmit),
      })
        .then(response => response.json())
        .then(data => {
          console.log('Data submitted successfully:', data);
        })
        .catch(error => {
          console.error('Error submitting data:', error);
        });
    },

    filterByLight(lightNumber) {
      // 切换灯号的过滤状态
      if (this.filterLightNumber === lightNumber) {
        this.filterLightNumber = null;
        this.showFiltered = false; // 取消筛选
      } else {
        this.filterLightNumber = lightNumber;
        this.showFiltered = true; // 显示筛选后的结果
      }
    },

    resetFilter() {
      this.filterLightNumber = null;
      this.showFiltered = false; // 恢复显示所有分拣后的板件
    },

    filterPanelsByLight(panels) {
      if (this.filterLightNumber === null) {
        return panels;
      }
      return panels.filter(panel => panel._cabinet_number == this.filterLightNumber && panel.sorted);
    },
  },

  computed: {
    filteredPendingOrders() {
      return this.pendingOrders.map(order => {
        const cabinets = order.cabinets.map(cabinet => {
          const filteredPanels = this.filterPanelsByLight(cabinet.panels);
          return filteredPanels.length > 0 ? { ...cabinet, panels: filteredPanels } : null;
        }).filter(cabinet => cabinet !== null);
        return cabinets.length > 0 ? { ...order, cabinets } : null;
      }).filter(order => order !== null);
    },

    filteredSortedOrders() {
      return this.sortedOrders.map(order => {
        const cabinets = order.cabinets.map(cabinet => {
          const filteredPanels = this.filterPanelsByLight(cabinet.panels);
          return filteredPanels.length > 0 ? { ...cabinet, panels: filteredPanels } : null;
        }).filter(cabinet => cabinet !== null);
        return cabinets.length > 0 ? { ...order, cabinets } : null;
      }).filter(order => order !== null);
    },

    filteredSortedPanels() {
      if (this.filterLightNumber === null) {
        return this.sortedPanels;
      }
      return this.sortedPanels.filter(panel => panel._cabinet_number == this.filterLightNumber);
    }
  }
};
</script>


<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 120px; /* 为了给固定灯光区域预留空间 */
}

.completion-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.completion-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.completion-content button {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.completion-content button:first-of-type {
  background-color: green;
  color: white;
}

.completion-content button:last-of-type {
  background-color: red;
  color: white;
}

/* 固定灯光区域样式 */
.lights {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #f9f9f9;
  padding: 10px 0;
  z-index: 1000;
}

.light {
  width: 60px;
  height: 60px;
  margin: 0 5px;
  background-color: gray;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.light.blinking {
  animation: blink 1s infinite;
}

.light.permanent-red {
  background-color: rgb(7, 252, 68) !important; /* 确保红色 */
}

@keyframes blink {
  0%, 100% {
    background-color: red;
  }
  50% {
    background-color: gray;
  }
}

/* 其他样式 */
.controls {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

input {
  margin-left: 10px;
  padding: 5px;
}

button {
  margin-left: 10px;
  padding: 5px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  vertical-align: middle;
  font-size: 1em;
  color: #2c3e50;
}

th {
  background-color: #f2f2f2;
}

.order-header, .cabinet-header {
  cursor: pointer;
  background-color: #e9ecef;
}

.cabinet-header .cabinet-name {
  padding-left: 20px;
}

.cabinet-header .cabinet-text {
  color: rgb(100, 2, 2);
}

.sub-table {
  width: 100%;
  border-collapse: collapse;
}

.sub-table th, .sub-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  vertical-align: middle;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.overlay-content {
  text-align: center;
}

.order-number, .cabinet-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.general-message, .highlight {
  font-size: 50px;
  color: #ffffff;
}

.highlight-red {
  color: rgb(0, 217, 255);
  font-size: 300px;
}

.highlight-blue {
  color: rgb(247, 104, 10);
  font-size: 150px;
}

.highlight-large {
  font-size: 1.05em !important;
  transition: font-size 0.5s !important;
}

.error-message {
  font-size: 50px;
  color: red;
}

.highlight {
  font-size: 20px;
  animation: highlight-animation 5s;
}

@keyframes highlight-animation {
  0%, 100% {
    background-color: rgb(6, 181, 250);
  }
  50% {
    background-color: transparent;
  }
}

.sorted {
  background-color: rgb(6, 181, 250);
}

/* 虚拟灯控样式 */
.virtual-light {
  width: 30px;
  height: 30px;
  background-color: gray;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
  text-align: center;
  position: relative;
}

.virtual-light.red {
  background-color: red;
}

.virtual-light.red::before {
  content: attr(data-number);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  text-align: center;
  width: 100%;
}

.virtual-light.blinking {
  animation: blink 1s infinite;
}
</style>